import * as React from "react"

function SvgSquarespace(props) {
  return (
    <svg
      baseProfile="tiny"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 36"
      overflow="visible"
      {...props}
    >
      <path d="M20 10.4l1.6 1.6c-.1.1-.1.2-.2.2l-10 10c-2 2-4.7 2.5-7.1 1.3-3.5-1.7-4.5-6.4-1.9-9.4.6-.7 1.2-1.3 1.8-1.9C6.6 9.9 9 7.5 11.3 5.1l.3-.3 1.7 1.7c-.1.1-.3.1-.4.2-2.9 2.8-5.7 5.7-8.6 8.6-1.5 1.5-1.6 3.5-.5 5.1 1.3 1.7 3.8 2 5.5.6.2-.2.4-.3.5-.5l9.8-9.8c.2-.1.3-.2.4-.3zM23.1 31.4l-1.7-1.7c.1-.1.3-.1.4-.3l8.5-8.5c1.6-1.6 1.6-4 0-5.5-1.3-1.4-3.5-1.4-4.9-.1l-1.2 1.2-9.1 9.1c-.1.1-.2.3-.2.4l-1.7-1.7.2-.2 10.2-10.2c2.3-2.2 5.6-2.5 8-.7 2.8 2.1 3.3 6.3.9 8.9-1.3 1.4-2.6 2.7-4 4L23.6 31c-.2.2-.4.3-.5.4z" />
      <path d="M26 7.6l-1.7 1.7c-.2-.2-.3-.5-.6-.7-1.7-1.7-3.9-1.7-5.6 0l-9.9 9.9-.3.3-1.6-1.6.2-.2L16.6 6.9c2.3-2.3 5.6-2.5 8.1-.6.4.4.8.9 1.3 1.3zM8.8 28.6l1.7-1.7.6.6c1.7 1.7 4 1.7 5.7 0l9.9-9.9c.1-.1.2-.3.3-.4l1.7 1.7-.2.2c-3.4 3.4-6.7 6.8-10.1 10.1-2.1 2.1-5.2 2.5-7.6 1-.8-.4-1.4-1-2-1.6z" />
    </svg>
  )
}

export default SvgSquarespace
