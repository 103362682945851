import * as React from "react"

function SvgCart(props) {
  return (
    <svg
      baseProfile="tiny"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 36"
      overflow="visible"
      {...props}
    >
      <path d="M11.4 22.8h16.9c.4 0 .8-.3.9-.7L33 9c.1-.3 0-.6-.2-.8-.2-.2-.5-.4-.7-.4H9.3l-.7-3c-.1-.4-.5-.7-.9-.7H2c-.5 0-.9.4-.9.9s.4.9.9.9h4.9l3.4 15.2c-1 .4-1.7 1.4-1.7 2.6 0 1.5 1.3 2.8 2.8 2.8h16.9c.5 0 .9-.4.9-.9s-.4-.9-.9-.9H11.4c-.5 0-.9-.4-.9-.9 0-.6.4-1 .9-1zM10.5 29.3c0 1.5 1.3 2.8 2.8 2.8 1.5 0 2.8-1.3 2.8-2.8 0-1.5-1.3-2.8-2.8-2.8-1.5 0-2.8 1.3-2.8 2.8zM23.7 29.3c0 1.5 1.3 2.8 2.8 2.8 1.5 0 2.8-1.3 2.8-2.8 0-1.5-1.3-2.8-2.8-2.8-1.6 0-2.8 1.3-2.8 2.8z" />
    </svg>
  )
}

export default SvgCart
