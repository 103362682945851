import * as React from "react"

function SvgPos(props) {
  return (
    <svg
      baseProfile="tiny"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 36"
      overflow="visible"
      {...props}
    >
      <path d="M32.9 3.8H3.7c-1.2 0-2.1.9-2.1 2.1v18.8c0 1.2.9 2.1 2.1 2.1h12c-.5-.8-1-1.8-1.7-3.1H4.7V7h27.2v16.7h-1.4V26c0 .1 0 .4-.1.9h2.5c1.2 0 2.1-.9 2.1-2.1V5.9c0-1.1-.9-2.1-2.1-2.1zm.5 12.6c-.6 0-1-.5-1-1s.5-1 1-1c.6 0 1 .5 1 1s-.4 1-1 1z" />
      <path d="M27.7 15.6h-.1c-.7 0-1.2.6-1.2 1.2v1.1h-.2v-1.1c0-.7-.6-1.2-1.2-1.2h-.1c-.7 0-1.2.6-1.2 1.2v1.1h-.2v-1.1c0-.7-.6-1.2-1.2-1.2h-.1c-.7 0-1.2.6-1.2 1.2v1.1h-.1v-7.1c0-.7-.6-1.2-1.2-1.2h-.1c-.7 0-1.2.6-1.2 1.2v10.8c-.3-.4-.6-.8-.9-1.1-.6-.8-1.1-1.4-1.6-1.5-.6-.1-1.1.1-1.4.5-.2.4-.2 1 .1 1.6.7 1.3 3.9 7 3.9 7 .3.6 1.4 2.7 5.2 2.7v-.1.1c2.8 0 4.1-1.3 4.7-2.4.7-1.2.7-2.4.7-2.4v-9.1c-.2-.8-.7-1.3-1.4-1.3z" />
    </svg>
  )
}

export default SvgPos
