import * as React from "react"

function SvgPackage(props) {
  return (
    <svg
      baseProfile="tiny"
      xmlns="http://www.w3.org/2000/svg"      
      viewBox="0 0 36 36"
      overflow="visible"
      {...props}
    >
        <g id="surface1">
            <path d="M 31.417969 15.019531 L 18 10.574219 L 4.582031 15.019531 L 0 22.296875 L 4.21875 23.695312 L 4.21875 31.433594 L 18 36 L 31.78125 31.433594 L 31.78125 23.695312 L 36 22.296875 Z M 27.375 15.902344 L 18 19.011719 L 8.625 15.902344 L 18 12.796875 Z M 3.21875 21.144531 L 5.726562 17.164062 L 16.390625 20.699219 L 13.886719 24.679688 Z M 6.328125 24.394531 L 14.789062 27.199219 L 16.945312 23.777344 L 16.945312 33.429688 L 6.328125 29.910156 Z M 19.054688 33.429688 L 19.054688 23.777344 L 21.210938 27.199219 L 29.671875 24.394531 L 29.671875 29.910156 Z M 22.113281 24.679688 L 19.609375 20.699219 L 30.273438 17.164062 L 32.78125 21.144531 Z M 22.113281 24.679688 "/>
            <path d="M 16.945312 0 L 19.054688 0 L 19.054688 7.042969 L 16.945312 7.042969 Z M 16.945312 0 "/>
            <path d="M 7.027344 3.222656 L 8.851562 2.167969 L 12.375 8.269531 L 10.546875 9.324219 Z M 7.027344 3.222656 "/>
            <path d="M 23.625 8.269531 L 27.148438 2.167969 L 28.972656 3.222656 L 25.453125 9.324219 Z M 23.625 8.269531 "/>
        </g>
    </svg>
  )
}

export default SvgPackage
