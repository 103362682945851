import * as React from "react"

function SvgTickets(props) {
  return (
    <svg
      baseProfile="tiny"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 36"
      overflow="visible"
      {...props}
    >
      <path d="M31.2 17.7c-.2.8-.8 1.3-1.6 1.3-.2 0-.5.2-.5.4s.1.5.3.6c.7.3 1.1 1.1.9 1.8l-.1.7c-.2 1-1.2 1.5-2.1 1.3-.2-.1-.5-.1-.8-.2-.4-.1-.7-.1-1.1-.2l-1.5-.3c-.6-.1-1.2-.2-1.7-.3-.7-.1-1.3-.3-2-.4-.7-.1-1.4-.3-2.1-.4-.7-.1-1.4-.3-2.1-.4-.7-.1-1.4-.3-2.1-.4-.7-.1-1.3-.3-2-.4-.6-.1-1.2-.2-1.8-.4-.5-.1-1.1-.2-1.6-.3-.4-.1-.9-.2-1.3-.3-.3-.1-.6-.1-.9-.2-.1 0-.3-.1-.4-.1.1.3 0 .7-.1 1.1-.3.7-1.1 1.1-1.9 1-.2 0-.5 0-.6.2l-.3.6c-.1.2 0 .4.2.6 0 0 23.8 11 23.9 11 .2 0 .5 0 .6-.2l.3-.6c.1-.2 0-.4-.2-.6-.6-.5-.8-1.3-.5-2 .3-.7 1.1-1.1 1.9-1 .2 0 .5 0 .6-.2l.3-.6c.1-.2 0-.4-.2-.6-.6-.5-.8-1.3-.5-2 .3-.7 1.1-1.1 1.9-1 .2 0 .5 0 .6-.2l.3-.6c.1-.2 0-.4-.2-.6-.6-.5-.8-1.3-.5-2 .3-.7 1.1-1.1 1.9-1 .2 0 .5 0 .6-.2l.3-.7c.1-.2 0-.4-.2-.5l-3.6-1.6-.1-.1z" />
      <path d="M2.8 17.5s25.7 5.2 25.8 5.2c.2 0 .4-.1.5-.4l.1-.7c0-.2-.1-.4-.3-.5-.7-.3-1.1-1.1-.9-1.9.2-.8.8-1.3 1.6-1.4.2 0 .4-.1.5-.4l.1-.5v-.2c0-.2-.1-.4-.3-.5-.7-.3-1.1-1.1-.9-1.9.2-.8.8-1.3 1.6-1.4.2 0 .4-.1.5-.4l.1-.7c0-.2-.1-.4-.3-.5-.7-.3-1.1-1.1-.9-1.9.2-.8.8-1.3 1.6-1.4.2 0 .4-.1.5-.4l.2-.8c0-.2-.1-.4-.3-.5L6.2 1.1c-.2 0-.4.1-.4.3l-.4 1.2c0 .2.1.4.3.5.7.3 1.1 1.1.9 1.9-.1.7-.8 1.3-1.5 1.3-.2 0-.4.1-.5.4l-.2.7c0 .2.1.4.3.5.7.3 1.1 1.1 1 1.9-.2.8-.8 1.3-1.6 1.4-.2 0-.4.1-.5.4l-.1.7c0 .2.1.4.3.5.7.3 1.1 1.1.9 1.9-.2.8-.8 1.3-1.6 1.4-.2 0-.4.1-.5.4l-.1.5c0 .2.1.4.3.5zm22-8.4c.1-.4.5-.7 1-.6.4.1.7.5.6 1l-1.1 5.4-.7 3.3c-.1.4-.4.7-.8.7h-.2c-.4-.1-.7-.5-.6-1l.7-3.7 1.1-5.1zm-14.9-3c.1-.4.5-.7 1-.6.4.1.7.5.6 1l-.4 1.9-.4 2.1-.3 1.7-.6 3.1c-.1.4-.4.7-.8.7h-.2-.1c-.4-.1-.7-.5-.6-1l.1-.6.3-1.6 1.4-6.7z" />
    </svg>
  )
}

export default SvgTickets
