import * as React from "react"

function SvgDiscounts(props) {
  return (
    <svg
      baseProfile="tiny"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 36"
      overflow="visible"
      {...props}
    >
      <path d="M15.1 23.1l4.6-13.7c.2-.5-.1-1.1-.6-1.2-.6-.2-1.1.1-1.3.6l-4.6 13.7c-.2.5.1 1.1.6 1.2h.3c.4.1.8-.2 1-.6zM21.9 18.8c1.6 0 2.9-1.3 2.9-2.9 0-1.6-1.3-2.9-2.9-2.9S19 14.3 19 15.9s1.3 2.9 2.9 2.9zm0-3.8c.5 0 1 .4 1 1 0 .5-.4 1-1 1s-1-.4-1-1 .5-1 1-1zM10.9 13C9.3 13 8 14.3 8 15.9s1.3 2.9 2.9 2.9 2.9-1.3 2.9-2.9c0-1.6-1.3-2.9-2.9-2.9zm0 3.9c-.5 0-1-.4-1-1 0-.5.4-1 1-1s1 .4 1 1-.4 1-1 1z" />
      <path d="M34.8 12.8l-3.4-4.5.1-.9c0-.3-.1-.6-.3-.8l-2.1-2.1 2.1-2.1c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0l-2.1 2.1L25.6 1c-.2-.2-.5-.3-.8-.3l-9.6 1.4c-.2 0-.4.1-.5.3L2.9 14.3c-1.4 1.4-1.4 3.6 0 5L13 29.4c.2.2.4.4.7.5v.4c0 2 1.6 3.6 3.6 3.6h14.2c2 0 3.6-1.6 3.6-3.6V13.4l-.3-.6zM4.3 18c-.6-.6-.6-1.7 0-2.3L16 4l8.8-1.3 1.7 1.7-1.4 1.4-.7-.7c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l2.7 2.7c.2.2.4.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4l-.7-.7 1.4-1.4 1.7 1.7-1.3 8.8-11.5 11.9c-.3.3-.7.5-1.1.5s-.8-.2-1.1-.5L4.3 18zm28.8 12.3c0 .9-.7 1.6-1.6 1.6H17.2c-.9 0-1.6-.7-1.6-1.5.9 0 1.7-.4 2.4-1l11.9-11.9c.1-.1.2-.3.3-.5l.9-6 2 2.7v16.6z" />
    </svg>
  )
}

export default SvgDiscounts
