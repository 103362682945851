import * as React from "react"

function SvgToast(props) {
  return (
    <svg
      baseProfile="tiny"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 36"
      overflow="visible"
      {...props}
    >
      <path d="M33 23.9c0-2-.1-4-.3-6-.1-1.3-.6-3.1-2.1-3.4h.6c1.7-.9 1.6-3.1 1-4.6-.8-2.1-2.7-3.5-4.5-4.5-6.7-3.6-15.6-3.3-22.1.7-1.6 1-3.6 3-2.7 5.3.2.6.6 1.1 1 1.5.2.2.6.7.6.7-.9 1.2-1.5 2.2-1.9 3.7-1.2 4.3-1 7-.7 10.5.2 2.1.9 5.3 3.4 5.7 2 .3 4.2-.4 6.2-.6 2.2-.3 4.4-.7 6.7-.6 3.1.1 6.3.8 9.4.9 1.7 0 4.4.2 4.9-2 .5-2.1.5-4.2.5-6.4v-.9zm-3.6 3.6c-.8 1.8-3 1.6-4.5 1.4-2.3-.2-4.6-.8-6.9-.8-2.1-.1-4.1.3-6.1.8-1.5.3-3.6 1.2-4.9 0-1.5-1.3-1.8-3.4-1.8-5.3 0-1.7.2-3.3.5-4.9.2-1 .5-2 .9-2.9.5-1.4 1.3-2.2 1.4-2.1-.5-.5-1-.7-1.3-1.3-1-2.1 1.5-4 2.9-4.6 4.9-2.2 11.8-2 16.4.8 1.4.9 4.2 3.9 1.6 5.4 1.6.8 2 3.1 2.2 4.7.3 2.6.2 5.3-.2 7.9 0 .3-.1.6-.2.9z" />
    </svg>
  )
}

export default SvgToast
